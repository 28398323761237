(function (angular) {
    angular.module('MyHippoProducer.Controllers').controller('ReportsController', ReportsController);
    function ReportsController ($log, $scope, $state, $timeout, APIService, UserService, $window, toaster) {
        $scope.reports = [];
        const userOrgId = UserService.getOrgId();
        APIService.getReports(userOrgId).then(reports => {
            if (reports) {
                const { YTD } = reports;
                if (YTD.length === 1) {
                    $scope.ytdReport = YTD[0];
                }
            }
        });

        $scope.downloadYTDReport = (url) => {
            APIService.downloadYTDReport(url).then(documentUrl => {
                const emailRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
                if (typeof documentUrl === 'string' && emailRegex.test(documentUrl)) {
                    toaster.pop('success', 'Opening report');
                    $window.open(documentUrl, '_blank');
                } else {
                    toaster.pop('error', 'Unable to retrieve report');
                }
            });
        };
    }
})(window.angular);
